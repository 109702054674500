.resizer {
    width: 100%;
    height: 4px;
    cursor: ns-resize;
    position: relative;
    &::after {
        opacity: 0.65;
        content: "";
        width: 20px;
        height: 2px;
        background: var(--resizer-color);
        position: absolute;
        top: 1px;
        left: calc(50% - 10px);
    }
    &:hover {
        &::after {
            opacity: 1;
        }
    }
}
