.model-select {
    .category-menu {
        min-height: 645px;
        height: calc(60vh + 45px);
    }
    .model-list {
        min-height: 600px;
        height: 60vh;
        overflow-y: scroll;
        > div {
            display: inline-block;
        }
    }
    .model-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;
    }
}

.category-total-size {
    margin-left: 20px;
}
