.panel-title {
    height: 30px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
}

.panel-title .anticon {
    margin-right: 10px;
    cursor: pointer;
    transition: all 0.1s ease;
}

.panel-body {
    transition: all 0.1s ease;
    opacity: 1;
}

.panel-collapse .panel-body {
    max-height: 0 !important;
    opacity: 0;
    pointer-events: none;
}

.panel-collapse .panel-title .anticon {
    transform: rotate(-90deg);
}


.psman-scroll-area {
    overflow: auto;
    height: 200px
}

.psman-scroll-area::-webkit-scrollbar-track {
    background-color: transparent !important;
} 