.flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.tag-label-text {
    margin-right: 0.1rem;
}

.tag-label {
    max-width: 160px;
    white-space: normal;
}

.tag-button {
    margin-left: 0.25rem;
    & > * {
        margin-left: 0.25rem;
        width: 20px;
        padding: 0;
    }
}

.weight-identifier {
    margin-right: 0.28rem;
}
