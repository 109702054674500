* {
    outline: none;
}

.cv {
    /* border: 2px none; */
    background-color: #b0c4de;
    border-style: dotted;
    /* margin-left: 120px; */
    /* margin-right: auto; */
    width: 1024px;
    height: 1024px;
    display: block
}

body {
    user-select: none;
}

.hidden {
    display: none;
}

#canvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 15;
    user-select: none;
    background: #000;
}

.react-draggable .draggable {
    cursor: move;
}

.top-divider {
    margin: 0 !important;
}

.toolsbar {
    position: fixed;
    right: 320px;
    top: 0px;
    padding: 0px 16px;
}

.resources-panel {
    position: fixed;
    left: 0;
    bottom: 0;
}

.history-panel {
    position: fixed;
    right: 0px;
    top: 0px;
}

.tool-panel {
    min-width: 330px;
}

.right-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 999;
}

.mask-panel {
}

.layer-panel {
}

    
.dream-panel {
    position: fixed;
    background-color: #fff;
    padding: 5px;
    bottom: 0px;
    right: 320px;
}

.ant-form-item {
    margin-bottom: 5px !important;
}

.ant-btn-icon-only {
    display: flex;
    align-items: center;
    justify-content: center;
}


.no-bg-scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: transparent
}
.no-bg-scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
}
.no-bg-scrollbar:active::-webkit-scrollbar-thumb,
.no-bg-scrollbar:focus-within::-webkit-scrollbar-thumb,
.no-bg-scrollbar:focus::-webkit-scrollbar-thumb,
.no-bg-scrollbar:hover::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,.2);
    border-radius: 10px;
}
.no-bg-scrollbar::-webkit-scrollbar-thumb:hover,
.no-bg-scrollbar::-webkit-scrollbar-thumb:active  {
    background: rgba(0,0,0,.35) !important;
}
.no-bg-scrollbar::-webkit-scrollbar-corner {
    background: transparent;
}
.no-bg-scrollbar::-webkit-scrollbar-button {
    display: none;
}
  
.no-padding-modal .ant-modal-content {
    padding: 0;
}
.no-bg-modal .ant-modal-content, .no-bg-modal .ant-modal-header {
    background: none;
}

.rc-scrollbars-track {
    background: red !important;
}

.resource-grid .ag-cell, .ag-full-width-row .ag-cell-wrapper.ag-row-group{
    padding-left: 2px;
    padding-right: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.ant-dropdown-menu-submenu-popup ul {
    margin-left: -8px;
}