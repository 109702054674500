.main-title {
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0;
}

.tag-wrapper {
    margin-top: 10px;
    height: calc(100vh - 285px);
    overflow-y: scroll;
    width: calc(100% + 15px);
}
