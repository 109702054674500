button:has(.hidden-close) {
    display: none !important;
}

.dr-title {
    height: 30px;
    background: #4e4e4e;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
    position: relative;
}

.no-image {
    display: flex;
    align-items: center;
    justify-content: center;
}

.side-ckpt {
    width: 200px;
    background: #3c3c3c;
    .side-ckpt-select {
        display: flex;
        height: 25px;
        align-items: top;
    }
}

.side-ckpt-tags-wrapper {
    height: 470px;
    background: #3c3c3c;
    overflow-y: scroll;
}

.ckpt-tag,
.lora-tag {
    word-break: break-all;
    display: inline-block;
    padding: 0 10px;
    margin: 0 5px 3px 0;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
    }
}

.ckpt-tag {
    background-color: #46006b;
}

.lora-tag {
    background-color: #00216b;
}

.side-ckpt-tags-list {
    padding: 5px;
}

.top-lora-wrapper {
    width: 100%;
    // height: 225px;
    overflow-x: scroll;
    background: #363636;
    .top-lora-list {
        display: flex;
        flex-direction: row;
        padding-right: 5px;
    }
    .top-lora-item {
        flex-shrink: 0;
        padding: 5px 5px 0;
        background: #525252;
        margin: 1px 1px 0 0;
        display: flex;
        align-items: start;

        .top-lora-item-tags {
            margin: 0 5px;
            width: 140px;
            height: 180px;
            overflow-y: scroll;
        }
    }

    .top-lora-item.add-lora {
        width: 100px;
        height: 191px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
            background: #5d5d5d;
        }
    }
}

.dr-main {
    height: 450px;
    overflow-y: scroll;
    textarea {
        width: calc(100% - 10px);
        height: calc(50% - 5px) !important;
        margin: 5px 5px 0 5px;
    }
}

.dr-footer {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .cancel-btn {
        position: absolute;
        right: 5px;
    }
}

.dr-tags-wrapper {
    height: 500px;
    padding: 5px;
    background: #3c3c3c;
    overflow-y: scroll;
}

.info-btn {
    position: absolute;
    right: 10px;
    bottom: 10px;
    padding: 1px;
    font-size: 16px;
    cursor: pointer;
    color: #fff;
    filter: drop-shadow(1px 1px 2px rgb(0 0 0 / 50%)) drop-shadow(0px 5px 15px rgb(0 0 0 / 60%));
}

.model-item {
    width: 200px;
    height: fit-content;
    padding: 5px;
    position: relative;
    .info-btn {
        right: 10px;
        bottom: 10px;
    }
    .model-changing {
        left: 5px;
        top: 5px;
        width: 190px;
        height: 190px;
        position: absolute;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
        font-size: 30px;
        background-color: rgba(0, 0, 0, 0.5);
    }
    .no-image,
    img {
        width: 190px;
        height: 190px;
        background: #282828;
        object-position: top center;
        object-fit: cover;
    }
    .model-item-title {
        height: 30px;
        line-height: 30px;
        padding: 0 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        background: #4e4e4e;
    }

    &.checkable {
        cursor: pointer;
    }
    &.disable {
        opacity: 0.5;
    }
}

.selected-lora-item {
    position: relative;
    .lora-title {
        position: absolute;
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 30px;
        line-height: 30px;
        overflow: hidden;
        text-overflow: clip;
        padding: 0 5px;
    }

    width: 150px;
    .no-image,
    img {
        width: 150px;
        height: 150px;
        background: #282828;
        object-position: top center;
        object-fit: cover;
    }

    .lora-weight {
        margin: 3px 0;
        height: 30px;
        font-size: 12px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        line-height: 25px;
        .lora-weight-span {
            display: inline-block;
            margin-left: 3px;
            width: 50px;
            height: 25px;
            line-height: 25px;
            background: #000;
            border-radius: 5px;
            text-align: center;
            cursor: text;
        }
    }

    .lora-del {
        position: absolute;
        right: 30px;
        bottom: 40px;
        padding: 1px;
        border-radius: 15px;
        cursor: pointer;
        font-size: 16px;
        filter: drop-shadow(1px 1px 2px rgb(0 0 0 / 50%)) drop-shadow(0px 5px 15px rgb(0 0 0 / 60%));
    }

    .info-btn {
        right: 5px;
        bottom: 40px;
    }
}

.ptab {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    padding: 0 5px;
    cursor: pointer;
    &.active {
        background: #6f6f6f;
    }
}

.base-model-version {
    position: absolute;
    left: 10px;
    top: 10px;
    border-radius: 4px;
    box-shadow: rgba(37, 38, 43, 0.2) 1px 2px 3px -1px;
    background: rgba(30, 133, 230, 0.4);
    font-size: 10px;
    padding: 0 8px;
    line-height: 16px;
}
