.cn-tabs {
    li {
        display: block;
        list-style: none;
        padding: 6px 0;
        // border-bottom: 1px solid rgba(253, 253, 253, 0.05);
        // &:first-child {
        //     border-top: 1px solid rgba(253, 253, 253, 0.05);
        // }
    }
    padding-bottom: 40px;
}

.cn-actions {
    position: absolute;
    bottom: 0;
    height: 30px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-right: 20px;
    column-gap: 5px;
}

.control-net-modal {
    .layer-style-btns {
        justify-content: space-between;
        align-items: center;
        .preset-btns button {
            margin-left: 0;
        }
    }
}
