.main-panel {
    --mp-bg-color: #4d4d4d;
    --mp-border-color: #424242;
    --mp-hover-color: #454545;
    --mp-active-color: #383838;
    --mp-tab-bg-color: var(--mp-border-color);
    --mp-tab-bg-active-color: var(--mp-bg-color);
    --mp-tab-bg-hover-color: #4f4f4f;
    --mp-panel-bg-color: #4d4d4dcc;
    --mp-top-panel-border-color: #3131318b;
}

.main-panel {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    color: #d5d5d5;
    display: flex;
    align-items: top;
    background: var(--mp-panel-bg-color);
}

.toolbar {
    border-right: 1px solid var(--mp-border-color);
    padding-bottom: 20px;
    background: var(--mp-bg-color);
}

.tool-expand, .top-panel-bar {
    height: 26px;
    background: #424242;
    font-size: 8px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #383838;

}
.tool-expand:hover, .top-panel-bar:hover {
    background: #535353;
}
.top-panel-bar .more {
    color: #fff;
    cursor: pointer;
    line-height: 26px;
    padding: 0 3px;
}
.top-panel-bar .more span {
    font-size: 14px;
}

.tool-expand {
    justify-content: flex-end;
    padding-right: 10px;
    cursor: pointer;
}

.top-panel-bar {
    cursor: move;
    padding-right: 2px;
}

.tool-expand-btn {
    font-size: 10px;
    display: flex;
    justify-content: center;
}

.tool-item {
    position: relative;
}
.tool-item:hover {
    background: var(--mp-hover-color); 
}
.tool-item.active {
    background: var(--mp-active-color);
}

.tool-item-content {
    padding: 8px;
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.tool-devider {
    background:var(--mp-border-color);
    height: 1px;
    width: 100%;
    margin: 10px 0;
}

.tool-label {
    margin-right: 6px;
    font-size: 14px;
}

.tool-icon {
    font-size: 18px;
    display: flex;
    justify-content: center;
}

.tool-tips {
    display: none;
    position: absolute;
    right: -185px;
    top: 10px;
    width: 180px;
    height: 80px;
    padding: 5px 10px;
    border-radius: 10px;
    background: #fff;
    border: 1px solid #000;
    color: #000;
    z-index: 9999;
}
.tool-tips::before {
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    left: -10px;
    top: 0;
    height: 100%;
}

.tool-tips .tool-tips-help {
    font-size: 12px;
    position: absolute;
    color: #1677ff;
    right: 10px;
    bottom: 5px;
    cursor: pointer;
}

.tool-tips.active {
    display: block;
}

.toolbar.expanded .tool-label {
    display: none;
}

.top-panel {
    border-bottom: 1px solid var(--mp-top-panel-border-color);
}

.top-panel-tabs {
    display: flex;
    font-size: 14px;
    background: var(--mp-tab-bg-color);
}
.top-panel-tab {
    padding: 5px 8px;
}
.top-panel-tab:not(.active):hover {
    background: var(--mp-tab-bg-hover-color);
}
.top-panel-tab.active {
    background: var(--mp-tab-bg-active-color);
}

.top-panel-item {
    padding: 12px;
    display: none;
}

.top-panel-item.active {
    display: block;
}

.tool-panel {
    padding: 6px;
}