.layer-style-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.layer-style-options {
    height: 320px;
    overflow-y: scroll;
}

.layer-style-btns {
    display: flex;
    justify-content: flex-end;
}

.layer-style-btns button {
    margin-left: 20px;
}