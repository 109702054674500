.cart-container {
    padding: 5px 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 200px);
    min-height: 0;
}

.scrollable {
    overflow: auto;
    flex: 1;
    //margin-bottom: 1.5rem;
}

.text-center {
    text-align: center;
}

.btn-block {
    display: block;
    width: 100%;
    .btn-group {
        width: 100%;
        display: flex;
        > .btn {
            width: 100%;
        }
    }
    > .btn {
        width: 100%;
    }
}

.subcart-container {
    margin-bottom: 1.5rem;
}

.el-tree {
    :deep(.el-tree-node__label) {
        margin-right: 1rem;
    }
    :deep(.el-tree-node__content) {
        height: auto;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }
}

.mb-bottom {
    // margin-bottom: 1rem;
    display: flex;
    padding-top: 10px;
}

.cart-title {
    margin: 0.75rem 0;
}
