.model-detail-column {
    background: #25262b;
}

.model-detail-table .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none !important;
    &.model-detail-column {
        background: #25262b !important;
    }
}

.model-edit-image {
    width: 100px;
    height: 100px;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    .edit-image-btns {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transition: all 0.3s;
        background-color: transparent;
        display: flex;
        justify-content: space-around;
        align-items: center;
        span {
            height: fit-content;
            opacity: 0;
            transition: all 0.3s;
            font-size: 20px;
        }
        &:hover {
            background-color: rgba(0, 0, 0, 0.5);
            span {
                opacity: 0.6;
                &:hover {
                    opacity: 1;
                }
            }
        }
    }
    img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        object-position: top center;
    }
}

.model-description {
    white-space: pre-wrap;
    padding: 20px 10px;
    user-select: text;
}

.slide-item {
    padding: 0 10px 10px 10px;
    height: 400px;
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    .slide-image {
        max-width: 100%;
        max-height: 100%;
        cursor: pointer;
        position: relative;
    }
    .meta-btn {
        position: absolute;
        z-index: 1;
        right: 10px;
        bottom: 10px;
        padding: 1px;
        font-size: 20px;
        opacity: 0.65;
        pointer-events: all;
        color: #fff;
        filter: drop-shadow(1px 1px 2px rgb(0 0 0 / 50%)) drop-shadow(0px 5px 15px rgb(0 0 0 / 60%));
        transition: opacity 150ms ease;
        &:hover {
            opacity: 1;
        }
    }
    img {
        object-position: center center;
        object-fit: cover;
        max-height: 100%;
        max-width: 100%;
        border-radius: 5px;
    }
}

.carousel {
    position: relative;
    .back-btn,
    .next-btn {
        width: 50px;
        height: 50px;
        border-radius: 50px;
        position: absolute;
        background: none;
        border: none;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0px 20px 25px -5px,
            rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
        transition: opacity 150ms ease;
        color: #000;
        pointer-events: all;
        background-color: #fff;
        font-size: 16px;
        opacity: 0.65;
        top: calc(50% - 25px);
        &:hover {
            opacity: 1;
        }
    }
    .back-btn {
        left: 10px;
    }
    .next-btn {
        right: 10px;
    }

    .carousel__slide-focus-ring {
        display: none;
    }
}

.model-meta {
    width: 350px;
    .param-name {
        font-weight: 500;
        margin-right: 10px;
    }
    pre {
        font-size: 12px;
        line-height: 1.55;
        border-radius: 4px;
        padding: 10px;
        white-space: normal;
        word-break: break-word;
        color: rgb(193, 194, 197);
        background: rgb(44, 46, 51);
        max-height: 70px;
        overflow: auto;
        margin: 0;
    }
    code {
        line-height: 1.55;
        padding: 2px calc(5px);
        border-radius: 4px;
        color: rgb(193, 194, 197);
        background-color: rgb(44, 46, 51);
        font-size: 12px;
        flex: 1 1 0%;
        text-align: right;
        overflow: hidden;
        white-space: nowrap;
    }
    .param-prompt {
        display: flex;
        justify-content: space-between;
    }
    .param-item {
        display: flex;
        justify-content: space-between;
    }
    .param-grid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
        gap: 10px 16px;
    }
}

.edit-preview.model-edit-image {
    cursor: pointer;
}

.model-preview {
    width: 300px;
    height: 400px;
    margin: 0 auto;
    img {
        object-position: center center;
        object-fit: cover;
        max-height: 100%;
        max-width: 100%;
        border-radius: 5px;
    }
}

.preview-back-btn,
.preview-next-btn {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 50px;
    position: absolute;
    background: none;
    border: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0px 20px 25px -5px,
        rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    transition: opacity 150ms ease;
    color: #000;
    pointer-events: all;
    background-color: #fff;
    font-size: 16px;
    opacity: 0.65;
    top: calc(50% - 25px);
    &:hover {
        opacity: 1;
    }
    z-index: 1;
}
.preview-back-btn {
    left: 8vw;
}
.preview-next-btn {
    right: 8vw;
}

.preview-modal {
    padding: 0;
    margin: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    .ant-modal-content {
        background: none;
        padding: 0;
    }
    .ant-modal-close {
        display: none;
    }
}
