.primary {
    color: hsl(210, 100%, 62%);
}

.warning {
    color: #cc9900;
}

.success {
    color: #4eb183;
}

.edit {
    display: inline-block;
    width: 96px;
    &.hidden {
        display: none;
    }
}

.nowrap {
    white-space: nowrap;
}
