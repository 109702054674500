.config-panel-options {
    height: 320px;
    overflow-y: scroll;
}

.config-panel-btns {
    display: flex;
    justify-content: flex-end;
}

.config-panel-btns button {
    margin-left: 20px;
}